<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.smsHistory') }}</h5>
        </div>
          <div class="modal-body">
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.transactionId') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.transactionId}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.createdDate') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.createdDate | formatDate}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.lastModifiedDate') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.lastModifiedDate | formatDate}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.sendStatus') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.sendStatus}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.deliveryStatus') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.deliveryStatus}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.recipient') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.recipient}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.message') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.message}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.templateId') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.smstemplate.templateId}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.nameTemplate') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.smstemplate.templateName}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.content') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.smstemplate.content}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.status') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.smstemplate.status}}</label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-3 col-form-label font-weight-bold">{{ $t('common.type') }}:</label>
                    <label class="col-lg-7 col-form-label">{{smsHistory.smstemplate.type}}</label>
                </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >Close</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmsHistory from "../../../model/sms-history-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const SmsTemplateRepository = RepositoryFactory.get("smsTemplate");
import { mapState, mapMutations, mapActions } from 'vuex';
import { uuid } from 'vue-uuid';

export default {
  data() {
    return {
      smsHistory: new SmsHistory(),
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  filters: {
      formatDate: function(value) {
          return moment(value).format("DD-MMM-YYYY HH:mm:ss");
      }
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
        var vx = this;
        this.smsHistory = Object.assign({}, this.dataEdit);
    },
  },
  mounted() {
    var vx = this;
    $("#" + this.modalName).modal();
    this.updateData();

  }
}
</script>
