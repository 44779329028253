export default function SmsHistory() {
    return {
        id: null,
        createdDate: null,
        lastModifiedDate: null,
        transactionId: null,
        sendStatus: null,
        deliveryStatus: null,
        recipient: null,
        message: null,
        smstemplate: {
            id: null,
            templateId: null,
            templateName: null,
            content: null,
            status: null,
            type: null
        }
    };
}
