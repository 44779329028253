export default function SmsTemplate() {
    return {
        id: null,
        templateId: null,
        templateName: null,
        content: null,
        status: null,
        type: null
    };
}
