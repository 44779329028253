<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.smsTemplate') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="smsTemplate.id"
            />
            <div class="form-group row" v-if="smsTemplate.id != null">
                <label class="col-lg-3 col-form-label">{{ $t('common.templateId') }}:</label>
                <div class="col-lg-8 col-form-label">
                    {{ smsTemplate.templateId }}
                </div>
            </div>
            <div class="form-group row" v-else>
              <label class="col-lg-3 col-form-label">{{ $t('common.templateId') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="smsTemplate.templateId"
                  v-validate="'required'"
                  data-vv-as="Template Id"
                  name="templateId"
                  :class="{'is-invalid': errors.has('templateId') }"
                />
                <div
                  v-show="errors.first('templateId')"
                  class="invalid-feedback"
                >{{ errors.first('templateId') }}</div>
              </div>
              <div class="col-lg-2">
                <button type="button" class="btn btn-default btn-bold btn-font-md pull-right" @click="generate()">Generate</button>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">{{ $t('common.nameTemplate') }}:</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="smsTemplate.templateName"
                  v-validate="'required'"
                  data-vv-as="Nama Template"
                  name="nama"
                  :class="{'is-invalid': errors.has('nama') }"
                />
                <div
                  v-show="errors.first('nama')"
                  class="invalid-feedback"
                >{{ errors.first('nama') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">{{ $t('common.content') }}:</label>
              <div class="col-lg-8">
                <textarea class="form-control" v-model="smsTemplate.content" @keyup="count = smsTemplate.content.length"></textarea>
                <small>Characters : {{count}}</small>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">{{ $t('common.status') }}:</label>
                <div class="col-lg-8">
                    <select
                        class="form-control"
                        v-model="smsTemplate.status"
                        v-validate="'required'"
                        data-vv-as="Status"
                        name="status"
                        :class="{'is-invalid': errors.has('status') }"
                    >
                        <option :value="value" v-for="value in smsStatus" :key="value">{{value}}</option>
                    </select>
                    <div
                        v-show="errors.first('status')"
                        class="invalid-feedback"
                        >{{ errors.first('status') }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">{{ $t('common.type') }}:</label>
                <div class="col-lg-8">
                    <select
                        class="form-control"
                        v-model="smsTemplate.type"
                        v-validate="'required'"
                        data-vv-as="Tipe"
                        name="type"
                        :class="{'is-invalid': errors.has('type') }"
                    >
                        <option :value="value" v-for="value in smsType" :key="value">{{value}}</option>
                    </select>
                    <div
                        v-show="errors.first('type')"
                        class="invalid-feedback"
                        >{{ errors.first('type') }}</div>
                    <small>*Untuk sms yang menggunakan otp gunakan tipe PREMIUM</small>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >Close</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsTemplate from "../../../model/sms-template-model";
import SmsStatus from "../../../constants/sms-status";
import SmsType from "../../../constants/sms-type";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const SmsTemplateRepository = RepositoryFactory.get("smsTemplate");
import { mapState, mapMutations, mapActions } from 'vuex';
import { uuid } from 'vue-uuid';

export default {
  data() {
    return {
      smsTemplate: new SmsTemplate(),
      smsStatus: SmsStatus,
      count: 0,
      smsType: SmsType
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
      var vx = this;
      if (this.dataEdit) {
        this.smsTemplate = Object.assign({}, this.dataEdit);
        this.count = this.smsTemplate.content.length;
      }
    },
    submitData: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.smsTemplate.id) {
            this.updateById({ repository: SmsTemplateRepository.update, data: this.smsTemplate });
          } else {
            this.createNew({ repository: SmsTemplateRepository.create, data: this.smsTemplate });
          }
          return;
        }
      });
    },
    generate: function(){
        this.smsTemplate.templateId = this.$uuid.v4().substr(0,10);
    },
  },
  mounted() {
    var vx = this;
    $("#" + this.modalName).modal();
    this.updateData();

  }
}
</script>
